<template>
    <div class="accountadd-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新　增" to="/client/account/add" />
                <van-tab replace title="账号列表" to="/client/account/list" />
            </van-tabs>
        </div>
        <div class="account-form">
            <van-field v-model="dataForm.name" label="用户名" placeholder="请输入用户名" />
            <van-field v-model="dataForm.name" label="用户类型" readonly>
                <template #input>
                    <van-checkbox-group v-model="dataForm.roles">
                        <van-checkbox
                            v-for="(item, index) in roleOptions"
                            :name="item.id"
                            icon-size="0.16rem"
                            shape="square"
                        >
                            {{ item.name }}
                        </van-checkbox>
                    </van-checkbox-group>
                </template>
            </van-field>
            <van-field v-model="dataForm.email" label="邮箱" placeholder="请输入邮箱" />
            <van-field v-model="dataForm.password" label="密码" placeholder="请输入密码" type="password" />
            <van-field
                v-model="dataForm.password_confirmation"
                label="确认密码"
                placeholder="请再次输入密码"
                type="password"
            />
        </div>
        <div class="submit-button">
            <van-button :block="true" color="#DF5F0B" @click="onSubmit">
                {{ $route.query.detail ? "确认编辑" : "确认新增" }}
            </van-button>
        </div>
    </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { Toast } from "vant";
import { ADD_USER, EDIT_USER, GET_ALL_ROLE_LIST } from "@/api/permission";
import usePublic from "@/hooks/usePublic";

let { $route, $router } = usePublic();

onMounted(() => {
    getRoleList();
    if ($route.query.detail) getDetail();
});

let tabActive = ref(0);

// 角色列表
let roleOptions = ref([]);

let getRoleList = () => {
    GET_ALL_ROLE_LIST({}).then((res) => {
        roleOptions.value = res.data.data;
    });
};

// 获取详情
let getDetail = async () => {
    await nextTick();
    let detailData = JSON.parse($route.query.detail);
    let roles = detailData.roles_node.map((item) => {
        return item.id;
    });
    dataForm.value = {
        name: detailData.name,
        roles: roles,
        email: detailData.email,
        password: null,
        password_confirmation: null
    };
};

// 数据
let dataForm = ref({
    name: null,
    roles: [],
    email: null,
    password: null,
    password_confirmation: null
});

// 提交
let onSubmit = () => {
    if (!$route.query.detail) {
        ADD_USER({ ...dataForm.value }).then((res) => {
            if (res.data.code === 200) {
                Toast({
                    type: "success",
                    message: "新增成功",
                    onClose: () => {
                        $router.go(-1);
                    }
                });
            }
        });
    } else {
        EDIT_USER({ ...dataForm.value, id: JSON.parse($route.query.detail).id }).then((res) => {
            if (res.data.code === 200) {
                Toast({
                    type: "success",
                    message: "编辑成功",
                    onClose: () => {
                        $router.go(-1);
                    }
                });
            }
        });
    }
};
</script>

<style lang="scss" scoped>
.accountadd-page {
    min-height: 100vh;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        margin-bottom: 20px;
    }

    .account-form {
    }

    .submit-button {
        width: 320px;
        margin: 20px auto 0 auto;
        border-radius: 10px;
    }
}
</style>
